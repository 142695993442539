import { UsesMapView, createMapViewCleaner } from '../utility/map-view-memory';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { selectCurrentExtent, selectMapQuery } from '../store/map.selector';
import { selectAffectations, selectBuildingClasses } from '../../building/store/reference-data.selector';
import { firstValueFrom, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { updateExtent } from '../store/map.action';
import {
  AddressesLayerAttrs,
  BuildingsLayerAttrs,
  DossiersLayerAttrs,
  GenericAttributs,
  MapExtent
} from '../model/map-extent.model';
import { BuildingSummary } from '../../building/model/building.model';
import { Affectation } from '../../building/model/affectation.model';
import { BuildingClass } from '../../building/model/building-class.model';
import { translate } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { findAllGraphics, findLayer, getLayer, LAYER_NAME } from '../layers/map-layers';
import * as intl from '@arcgis/core/intl';
import Graphic from '@arcgis/core/Graphic';
import Map from '@arcgis/core/Map';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapView from '@arcgis/core/views/MapView';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';
import Search from '@arcgis/core/widgets/Search';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { selectSelectedBuildings } from '../../building/store/buildings.selector';
import {
  addSelectedBuildings,
  removeSelectedBuildings
} from '../../building/store/buildings.action';
import { orthoService, swissTopoService } from '../basemaps/basemaps';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Point from '@arcgis/core/geometry/Point';
import { Configuration } from '../../authentication/model/configuration.model';
import { Hit, Locator } from '../model/map.model';
import HitTestResultResults = __esri.HitTestResultResults;

const SWITZERLAND_COORDINATES_REFERENCE = 2056;
const LEFT_CLICK = 0;

@Component({
  selector: 'sibat-web-map',
  template: `
    <div class="embed-container">
      <div #mapDiv></div>
      <div id="basemapToggleNode"></div>
    </div>
    <div id="query-limit-message" class="info-container" #queryLimitMessage>
      <p>{{ 'building.map.queryLimitReached' | transloco }}</p>
    </div>
    <div class="info-container" #buildingInfoTooltip>
      <table>
        <tr>
          <td>
            <span class="info-attribute">{{ 'building.map.buildingInfo.egid' | transloco }}</span>
          </td>
          <td><span id="EGID"></span></td>
        </tr>
        <tr>
          <td>
            <span class="info-attribute">{{ 'building.map.buildingInfo.address' | transloco }}</span>
          </td>
          <td><span id="Address"></span></td>
        </tr>
        <tr>
          <td valign="top">
            <span class="info-attribute">{{ 'building.map.buildingInfo.assignment' | transloco }}</span>
          </td>
          <td><span id="Affectation"></span></td>
        </tr>
        <tr>
          <td>
            <span class="info-attribute">{{ 'building.map.buildingInfo.class' | transloco }}</span>
          </td>
          <td><span id="Assignment"></span></td>
        </tr>
      </table>
    </div>
    <div
      class="esri-widget esri-widget--button esri-widget esri-interactive lasso"
      [ngClass]="{ activeLasso: lassoIsActive }"
      title="{{ 'building.map.groupedSelection' | transloco }}"
      #selectByPolygonButton
    >
      <span class="esri-icon-feature-layer"></span>
    </div>
  `,
  styleUrls: ['./web-map.component.scss'],
})
export class WebMapComponent implements OnInit, OnChanges, OnDestroy, UsesMapView {
  @Input() registeredGisToken = false;
  @Input() configuration?: Configuration;
  @Input() centeredOnBuildings?: number[] | 'all';
  @Input() isEcabOrAbove = false;
  @Output() mapLoadedEvent = new EventEmitter<boolean>();
  @Output() selectionChange = new EventEmitter<void>();

  @ViewChild('mapDiv', { static: true }) private mapDiv: ElementRef;
  @ViewChild('queryLimitMessage', { static: true }) private queryLimitMessage: ElementRef;
  @ViewChild('buildingInfoTooltip', { static: true }) private buildingInfoTooltip: ElementRef;
  @ViewChild('selectByPolygonButton', { static: true }) private selectByPolygonButton: ElementRef;

  view?: MapView;
  buildingLayer?: FeatureLayer;
  dossierLayer?: FeatureLayer;

  mapQueryEnabled = false;
  mapQuerySubscription: Subscription;
  currentExtentSubscription: Subscription;

  selectedBuildingsSubscription: Subscription;

  currentExtent: MapExtent;
  lassoIsActive = false;
  public highlightedGraphics: any[] = [];
  public highlight?: { remove: () => void };

  affectationsSubscription: Subscription;
  affectations: Affectation[];
  buildingClassesSubscription: Subscription;
  buildingClasses: BuildingClass[];
  mapViewCleaner = createMapViewCleaner();

  constructor(private store: Store, private router: Router, private zone: NgZone, private localeService: TranslocoLocaleService) {}

  ngOnInit(): void {
    this.currentExtentSubscription = this.store.select(selectCurrentExtent).subscribe(currentExtent => {
      if (!!currentExtent) {
        this.currentExtent = currentExtent;
      }
    });
    this.affectationsSubscription = this.store.select(selectAffectations).subscribe(affectations => (this.affectations = affectations));
    this.buildingClassesSubscription = this.store
      .select(selectBuildingClasses)
      .subscribe(buildingClasses => (this.buildingClasses = buildingClasses));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.centeredOnBuildings) {
      if (this.centeredOnBuildings?.length) {
        void this.centerOnBuildings(this.centeredOnBuildings);
        return;
      }
    }
    if (this.registeredGisToken && this.configuration) {
      this.unregisterBuildingSelectionSubscription();
      this.unregisterMapQuerySubscription();
      this.initialize(this.configuration).then(() => {
        this.registerBuildingSelectionSubscription();
        this.registerMapQuerySubscription();
        this.mapLoadedEvent.emit(true);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.view) {
      this.saveExtent();
      this.mapViewCleaner.cleanup(this);
    }
    this.unregisterBuildingSelectionSubscription();
    this.unregisterMapQuerySubscription();
    this.currentExtentSubscription?.unsubscribe();
    this.affectationsSubscription?.unsubscribe();
    this.buildingClassesSubscription?.unsubscribe();
  }

  registerBuildingSelectionSubscription() {
    this.selectedBuildingsSubscription = this.store.select(selectSelectedBuildings).subscribe((selectedBuildings: BuildingSummary[]) => {
      let newSelection = selectedBuildings.map(selectedBuilding => selectedBuilding.id).sort();

      // optimize drawing list
      newSelection = [...this.getUpdatedListBeforeDraw(newSelection)];
      // draw function
      this.setBuildingSelection(newSelection, this);
    });
  }

  getUpdatedListBeforeDraw(newSelection: number[]) {
    const graphicsToRemove: any[] = [];
    // if buildingId no longer exist : remove it from graphics : instead of remove all
    this.view?.graphics?.map(graphic => {
      if (newSelection.indexOf(graphic.attributes[BuildingsLayerAttrs.BuildingId]) < 0) {
        graphicsToRemove.push(graphic);
      }
    });
    this.view?.graphics?.removeMany(graphicsToRemove);

    // if graphic exist : no need to draw it
    return newSelection.filter(buildingId => !Boolean(this.getGraphic(buildingId)));
  }

  async initialize(configuration: Configuration): Promise<MapView> {
    try {
      intl.setLocale(this.localeService.getLocale());

      const swissTopoBasemap = swissTopoService();
      const orthoBasemap = orthoService(configuration);

      const map = new Map({
        basemap: swissTopoBasemap,
      });

      this.view = new MapView({
        container: this.mapDiv.nativeElement,
        center: {
          x: 2577712.136,
          y: 1185115.723,
          spatialReference: { wkid: SWITZERLAND_COORDINATES_REFERENCE },
          type: 'point',
        },
        zoom: 4,
        map,
      });

      await this.view.when().then(() => this.restoreExtent());

      if (this.isEcabOrAbove) {
        this.dossierLayer = getLayer(configuration, LAYER_NAME.dossiers);
        map.add(this.dossierLayer);
      }
      this.buildingLayer = getLayer(configuration, LAYER_NAME.buildings);
      map.add(this.buildingLayer);
      map.add(getLayer(configuration, LAYER_NAME.friac));
      map.add(getLayer(configuration, LAYER_NAME.addresses));
      map.add(getLayer(configuration, LAYER_NAME.cases));
      if (!environment.production) {
        map.add(getLayer(configuration, LAYER_NAME.borders));
      }

      // polygonGraphicsLayer will be used by the sketchviewmodel
      // show the polygon being drawn on the view
      const polygonGraphicsLayer = new GraphicsLayer({
        id: 'buildings-selection',
      });
      map.add(polygonGraphicsLayer);

      this.view.ui.add(
        new BasemapToggle({
          view: this.view,
          nextBasemap: orthoBasemap,
          container: 'basemapToggleNode'
        }),
        'bottom-right'
      );

      // add the select by polygon button the view
      this.view.ui.add(this.selectByPolygonButton.nativeElement, 'top-left');

      // create a new sketch view model set its layer : lasso
      const sketchViewModel = new SketchViewModel({
        view: this.view,
        layer: polygonGraphicsLayer,
        pointSymbol: {
          type: 'simple-marker',
          color: [148, 0, 211, 0.25],
          size: '1px',
          outline: {
            color: [255, 0, 255, 0],
            width: 1,
          },
        },
      });

      const locatorOptions = {
        outFields: ['Address2'],
        singleLineFieldName: 'SingleLine',
        resultSymbol: {
          type: 'picture-marker',
          url: environment.markerIcon,
          size: 24,
          width: 24,
          height: 24,
          xoffset: 0,
          yoffset: 0
        },
        popupEnabled: false,
        minSuggestCharacters: 2
      };

      // initialize search widget
      const locators: Locator[] = [
        { name: 'address', relativePath: configuration.locatorAddress },
        { name: 'municipalities', relativePath: configuration.locatorMunicipalities },
        { name: 'egid', relativePath: configuration.locatorEgid },
        { name: 'npa', relativePath: configuration.locatorNpa },
        { name: 'parcel', relativePath: configuration.locatorParcel },
        { name: 'permit', relativePath: configuration.locatorPermit },
        { name: 'swissname', relativePath: configuration.locatorSwissname }
      ];

      const locatorSources = locators.map((locator, index) => ({
        ...locatorOptions,
        url: locator.name !== 'parcel' ?
            configuration.portalUrl + configuration.relativeLocatorPath + locator.relativePath :
            configuration.portalUrl + locator.relativePath,
        name: translate(`building.map.locators.${locator.name}`),
        placeholder: translate(`building.map.locators.${locator.name}`),
      }));

      const allSearchSources = [
        ...locatorSources,
        {
          layer: this.buildingLayer,
          placeholder: BuildingsLayerAttrs.BuildingId,
          maxResults: 5,
          searchFields: [BuildingsLayerAttrs.BuildingId],
          displayField: BuildingsLayerAttrs.BuildingId,
          name: BuildingsLayerAttrs.BuildingId.toUpperCase(),
        }
      ];
      const searchWidget = new Search({
        view: this.view,
        includeDefaultSources: false,
        sources: allSearchSources,
      } as any);

      this.view.ui.add(searchWidget, 'top-right');
      this.view.ui.add(this.buildingInfoTooltip.nativeElement, 'bottom-left');

      // initialize events
      this.addEventlisteners(this, this.selectByPolygonButton.nativeElement, sketchViewModel, polygonGraphicsLayer);
      this.view.on('click', this.onMapClickHandler.bind(this));
      this.view.on('double-click', this.onMapDoubleClickHandler.bind(this));
      this.view.on('pointer-move', this.onMapMoveHandler.bind(this));
      this.view.popup.autoOpenEnabled = false;

      return Promise.resolve(this.view);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  setBuildingSelection(buildingIds: number[], accessor: WebMapComponent) {
    if (buildingIds.length < 1) {
      return;
    }

    const query = accessor.buildingLayer?.createQuery();
    if (query) {
      query.where = `${BuildingsLayerAttrs.BuildingId} IN (${buildingIds.join(',')})`;
      query.outFields = [BuildingsLayerAttrs.BuildingId, BuildingsLayerAttrs.FidBat];

      accessor.buildingLayer?.queryFeatures(query).then(result => {
        result['features'].forEach(element => {
          accessor.addSelectionToMap(element['geometry'], element['attributes'][BuildingsLayerAttrs.BuildingId], accessor);
        });
      });
    }
  }

  saveExtent() {
    const extent = {
      x: this.view?.center.x,
      y: this.view?.center.y,
      zoom: this.view?.zoom,
    } as MapExtent;
    this.store.dispatch(updateExtent({ extent }));
  }

  restoreExtent() {
    // If data is stored, go back to last extent position
    if (this.view && this.currentExtent) {
      this.view.center = {
        x: this.currentExtent.x,
        y: this.currentExtent.y,
        spatialReference: {
          wkid: SWITZERLAND_COORDINATES_REFERENCE,
        } as SpatialReference,
      } as Point;
      this.view.zoom = this.currentExtent.zoom;
    }
  }

  // create geometries for specified building and add them to map
  async addSelectionToMap(geometry, buildingId: number, accessor) {
    const symbol = {
      type: 'simple-fill',
      style: 'solid',
      outline: {
        style: 'solid',
        color: [255, 0, 255],
      } as any,
      color: [148, 0, 211, 0.25],
    } as any;
    const polygonAttributes = {
      [BuildingsLayerAttrs.BuildingId]: buildingId,
    };
    const graphic = new Graphic({
      geometry,
      symbol,
      attributes: polygonAttributes,
    } as any);
    accessor.view.graphics.add(graphic);
  }

  addBuildingsToSelection(buildingIds: number[]) {
    this.store.dispatch(addSelectedBuildings({ buildingIds }));
  }

  removeBuildingsFromSelection(buildingIds: number[]) {
    this.store.dispatch(removeSelectedBuildings({ buildingIds }));
  }

  getGraphic(buildingId: number) {
    return this.view?.graphics.find(graphic => graphic.attributes[BuildingsLayerAttrs.BuildingId] === buildingId);
  }

  getLocalizedAffectation(affectations: string): string[] {
    const splitAffectations = affectations.split(',');
    return splitAffectations.map(affectation => ' ' + this.affectations.find(item => item.key === affectation)?.title ?? '');
  }

  getLocalizedBuildingClasses(buildingClassCode: number): string {
    return this.buildingClasses.find(item => item.code === buildingClassCode)?.name ?? '';
  }

  private unregisterBuildingSelectionSubscription() {
    if (this.selectedBuildingsSubscription) {
      this.selectedBuildingsSubscription.unsubscribe();
    }
  }

  private registerMapQuerySubscription() {
    this.mapQuerySubscription = this.store.select(selectMapQuery).subscribe(mapQuery => {
      this.mapQueryEnabled = !(mapQuery === '');
      this.mapQueryChanged(mapQuery);
    });
  }

  private unregisterMapQuerySubscription() {
    if (this.mapQuerySubscription) {
      this.mapQuerySubscription.unsubscribe();
    }
  }

  private async mapQueryChanged(mapQuery: string): Promise<void> {
    try {
      if (mapQuery && this.view && this.buildingLayer) {
        const layerView = await this.view.whenLayerView(this.buildingLayer);
        const query = this.buildingLayer.createQuery();
        if (query) {
          query.where = mapQuery;

          this.buildingLayer?.queryFeatureCount(query).then(resultCount => {
            if (this.highlight) {
              this.highlight?.remove();
            }
            if (this.buildingLayer && resultCount >= this.buildingLayer.capabilities.query.maxRecordCount) {
              this.queryLimitMessage.nativeElement.style.visibility = 'visible';
            } else {
              this.queryLimitMessage.nativeElement.style.visibility = 'hidden';
              query.outFields = [
                BuildingsLayerAttrs.ObjectID,
                BuildingsLayerAttrs.FidBat,
                BuildingsLayerAttrs.BuildingId,
                BuildingsLayerAttrs.FOSNR,
                BuildingsLayerAttrs.Color,
                BuildingsLayerAttrs.GKLAS,
                BuildingsLayerAttrs.GBAUP,
              ];
              this.buildingLayer?.queryFeatures(query).then(result => {
                this.highlightedGraphics = result.features;
                this.highlight = layerView.highlight(result.features);
              });
            }
          });
        } else if (this.highlight) {
          this.highlight.remove();
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private addEventlisteners(accessor: WebMapComponent, selectButton, sketchViewModel, polygonGraphicsLayer) {
    // click event for the selector tool button
    selectButton.addEventListener('click', () => {
      this.lassoIsActive = !this.lassoIsActive;
      if (this.lassoIsActive) {
        sketchViewModel.create('polygon', { mode: 'click' });
        accessor?.view?.on('double-click', () => {
          sketchViewModel.complete();
        });
      } else {
        sketchViewModel.cancel();
      }
    });

    sketchViewModel.on('create', event => {
      if (event.state === 'complete') {
        polygonGraphicsLayer.remove(event.graphic);
        this.selectFeaturesByLasso(event.graphic.geometry, accessor);
        // launch new selection with polygon
        sketchViewModel.create('polygon', { mode: 'click' });
      }
    });
  }

  private selectFeaturesByLasso(lassoGeometry, accessor: WebMapComponent) {
    if (accessor.buildingLayer) {
      // create a query and set its geometry parameter to the
      // polygon that was drawn on the view
      const query = {
        geometry: lassoGeometry,
        outFields: [BuildingsLayerAttrs.BuildingId],
        returnGeometry: true,
      };
      accessor.view?.whenLayerView(accessor.buildingLayer).then(() => {
        accessor.buildingLayer?.queryFeatures(query).then(results => {
          const graphics = results.features;
          const lassoSelectedBuildings = graphics
            .map(element => element['attributes'][BuildingsLayerAttrs.BuildingId])
            .filter(value => value != null);
          accessor.addBuildingsToSelection(lassoSelectedBuildings);
        });
      });
    }
  }

  private async onMapClickHandler(event: any): Promise<void> {

    if (this.lassoIsActive) {
      return;
    }

    if (event['button'] === LEFT_CLICK) {
      //  the hitTest() checks to see if any graphics in the view
      //  intersect the given screen point
      const response = await this.view?.hitTest(event);
        if (response?.results.length) {
          const buildingLayer = findLayer(response.results, LAYER_NAME.buildings);
          const friacLayerGraphics = findAllGraphics(response.results, LAYER_NAME.friac);
          const caseLayerGraphics = findAllGraphics(response.results, LAYER_NAME.cases);
          if (this.mapQueryEnabled) {
            const breakClick = !this.highlightedGraphics.some(element =>
              element.attributes[BuildingsLayerAttrs.BuildingId] === buildingLayer?.graphic.attributes[BuildingsLayerAttrs.BuildingId]
            );
            if (breakClick) {
              return;
            }
          }

          if (friacLayerGraphics.length > 0 || caseLayerGraphics.length > 0) {
            // if there is graphic friac on clic location
            this.view?.popup.open({
              features: [...caseLayerGraphics, ...friacLayerGraphics], // array of graphics or a single graphic in an array
              location: caseLayerGraphics[0].geometry || friacLayerGraphics[0].geometry,
            });
          } else {
            //select|deselect building
            const buildingFeatures = response.results
                        .filter(result => (result.graphic.layer.id === LAYER_NAME.buildings))
                        .map(result => result.graphic);
            this.updateSelectionWithFeatures(buildingFeatures, true);
          }
        }

    }
  }

  private async onMapDoubleClickHandler(event: any): Promise<void> {
    event.stopPropagation();
    if (this.lassoIsActive) {
      return;
    }

    this.view?.hitTest(event).then(response => {
      const buildingFeatures = response.results
                .filter(result => (result.graphic.layer.id === LAYER_NAME.buildings))
                .map(result => result.graphic);


      if (buildingFeatures.length > 1) {
        const uniqueBuildings = buildingFeatures.reduce((unique: Graphic[], building) => {
          const foundIndex = unique.findIndex(b =>
            b.attributes[BuildingsLayerAttrs.BuildingId] === building.attributes[BuildingsLayerAttrs.BuildingId]);
          if (foundIndex === -1) {
            unique.push(building);
          }
          return unique;
        }, []);
        if (uniqueBuildings.length > 1) {
          this.updateSelectionWithFeatures(buildingFeatures, false);
        } else {
          this.navigateToBuilding(response.results);
        }
      } else {
        const target = (this.navigateToBuilding(response.results) || this.navigateToArea(response.results));
        return target;
      }
    });

  }

  private navigateToBuilding(results: HitTestResultResults[]) {
    return this.navigateTo('building', BuildingsLayerAttrs.BuildingId, findLayer(results, LAYER_NAME.buildings));
  }

  private navigateToArea(results: HitTestResultResults[]) {
    return this.navigateTo('area', DossiersLayerAttrs.ID, findLayer(results, LAYER_NAME.dossiers));
  }

  private navigateTo(navigationSubRoot: string, attributeName: string, layer?: Hit): boolean {
    if (layer && layer.graphic.geometry.type === 'polygon' && layer.graphic.attributes) {
      const attribute = layer.graphic.attributes[attributeName];
      if (attribute) {
        void this.zone.run(() => this.router.navigate([`/${navigationSubRoot}/${attribute}`]));
        return true;
      }
    }
    return false;
  }

  private onMapMoveHandler(event: any): Promise<void> {
    // the hitTest() checks to see if any graphics in the view
    // intersect the x, y coordinates of the pointer
    return (
      this.view?.hitTest(event).then(response => {
        this.displayTooltip(response);
      }) ?? Promise.resolve()
    );
  }



  private updateSelectionWithFeatures(features: Graphic[], isSimpleClick: boolean): void {
    const buildingsToAdd = [] as number[];
    const buildingsToRemove = [] as number[];
    for (const feature of features) {
      const clickedBuildingId = feature['attributes'][BuildingsLayerAttrs.BuildingId];
      if (feature.geometry && feature.geometry.type === 'polygon' && this.view && clickedBuildingId) {
        //  Check if polygon is already drawn
        const doesPolygonExist = this.view.graphics.some(element =>
              element.attributes[BuildingsLayerAttrs.BuildingId] === clickedBuildingId);
         //if polygon already exists, remove it from the graphics and the list of selected
        if (doesPolygonExist) {
          buildingsToRemove.push(clickedBuildingId);
        } else {
          buildingsToAdd.push(clickedBuildingId);
        }
      }
    }
    this.addBuildingsToSelection(buildingsToAdd);
    if (isSimpleClick) {
      this.removeBuildingsFromSelection(buildingsToRemove);
    }
    if (buildingsToAdd.length > 0) {
      this.selectionChange.emit();
    }
  }

  private displayTooltip(response) {
    const buildingsLayer = findLayer(response.results, LAYER_NAME.buildings);
    if (buildingsLayer) {
      const addressLayer = findLayer(response.results, LAYER_NAME.addresses);
      const buildingInfoTooltip = this.buildingInfoTooltip.nativeElement;
      buildingInfoTooltip.style.visibility = 'visible';
      buildingInfoTooltip.querySelector('#EGID').innerHTML = buildingsLayer?.graphic?.attributes[BuildingsLayerAttrs.EGID]
        ? buildingsLayer?.graphic?.attributes[BuildingsLayerAttrs.EGID]
        : translate('building.map.buildingInfo.notDefined');
      buildingInfoTooltip.querySelector('#Affectation').innerHTML = buildingsLayer?.graphic?.attributes[BuildingsLayerAttrs.Affectations]
        ? this.getLocalizedAffectation(buildingsLayer?.graphic?.attributes[BuildingsLayerAttrs.Affectations])
        : translate('building.map.buildingInfo.toBeDefined');
      buildingInfoTooltip.querySelector('#Assignment').innerHTML = this.getLocalizedBuildingClasses(
        Number(buildingsLayer?.graphic?.attributes[BuildingsLayerAttrs.GKLAS])
      );
      buildingInfoTooltip.querySelector('#Address').innerHTML = addressLayer
        ? addressLayer?.graphic?.attributes[AddressesLayerAttrs.Adresse]
        : buildingsLayer?.graphic?.attributes[AddressesLayerAttrs.Adresse]
        ? buildingsLayer?.graphic?.attributes[AddressesLayerAttrs.Adresse]
        : translate('building.map.buildingInfo.notAvailable');
      return;
    }
    this.buildingInfoTooltip.nativeElement.style.visibility = 'hidden';
  }

  private async centerOnBuildings(buildingIds: number[] | 'all'): Promise<void> {
    let mapQuery: string | undefined;
    if (buildingIds === 'all') {
      mapQuery = await firstValueFrom(this.store.select(selectMapQuery));
    } else {
      mapQuery = `${BuildingsLayerAttrs.BuildingId} IN (${buildingIds.join(',')})`;
    }

    await this.changeExtentFromQueryFilter(mapQuery);
  }

  private async changeExtentFromQueryFilter(mapQuery) {
    if (this.buildingLayer) {
      const query = this.buildingLayer.createQuery();
      query.where = mapQuery;
      if (this.view) {
        query.outSpatialReference = this.view.spatialReference;
      }
      const results = await this.buildingLayer?.queryFeatures(query);
      if (results.features.length > 0) {
        this.view?.goTo(results.features);
        if (results.features.length === 1) {
          this.highlightBuilding(results.features[0], this);
        }
      }

    }
  }

  private highlightBuilding(feature: Graphic, accessor) {
    const symbol = {
      type: 'simple-fill',
      style: 'solid',
      outline: {
        style: 'solid',
        color: [255, 0, 255],
      } as any,
      color: [255, 255, 0, 0.9],
    } as any;

    const graphic = new Graphic({
      geometry: feature.geometry,
      symbol,
    } as any);
    accessor.view.graphics.add(graphic);
    setTimeout(() => {
      accessor.view.graphics.remove(graphic);
    }, 1500);
  }

}
